import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QsIDsService {
  // private serviceLabels = {
  //   qs_ID_01: 'Driver',
  //   qs_ID_02: 'Plumber',
  //   qs_ID_03: 'Plumber2',
  //   qs_ID_04: 'Plumber3',
  //   qs_ID_05: 'Plumber4',
  //   qs_ID_06: 'Plumber5',
  //   qs_ID_07: 'Plumber6',
  //   qs_ID_08: 'Plumber7',
  //   qs_ID_09: 'Plumber8',
  // };

  private serviceLabels = {
    qs_ID_01: 'Household cleaning',
    qs_ID_02: 'Cooking',
    qs_ID_03: 'Cooking and cleaning',
    qs_ID_04: 'Care taker',
    qs_ID_05: 'Vehicle washing',
    qs_ID_06: 'Deep cleaning units',
    
  };

  private _currentPage: number = 1;
  private _pageSize: number = 30;
  private _totalItems: number = 1;

  constructor() {}

  getServiceLabel(serviceName: string): string {

    // console.log(this.serviceLabels[serviceName])
   
    return this.serviceLabels[serviceName] || 'Unknown';
  }

  getServiceLabels(): string[] {
    return Object.values(this.serviceLabels);
  }

  getServiceIdByLabel(label: string): string | undefined {
    return Object.keys(this.serviceLabels).find(key => this.serviceLabels[key] === label);
  }


  get currentPage(): number {
    return this._currentPage;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  get totalItems(): number {
    return this._totalItems;
  }

  setCurrentPage(page: number): void {
    this._currentPage = page;
  }

  setPageSize(size: number): void {
    this._pageSize = size;
  }

  setTotalItems(total: number): void {
    this._totalItems = total;
  }

  
}
