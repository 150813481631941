import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;
import{ QsIDsService } from 'src/app/services/qs-ids.service'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-me-unit-listings',
  templateUrl: './me-unit-listings.component.html',
  styleUrls: ['./me-unit-listings.component.css'],
})
export class MeUnitListingsComponent implements OnInit {
  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private qsIDsService: QsIDsService
  ) {}

  spinnerForSearch: boolean = false;


  districts = [
    { value: "", label: "All districts" },
    { value: "Alappuzha", label: "Alappuzha" },
    { value: "Ernakulam", label: "Ernakulam" },
    { value: "Idukki", label: "Idukki" },
    { value: "Kannur", label: "Kannur" },
    { value: "Kasaragod ", label: "Kasaragod" },
    { value: "Kollam", label: "Kollam" },
    { value: "Kottayam", label: "Kottayam" },
    { value: "Kozhikode", label: "Kozhikode" },
    { value: "Malappuram", label: "Malappuram" },
    { value: "Palakkad", label: "Palakkad" },
    { value: "Pathanamthitta", label: "Pathanamthitta" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Thrissur", label: "Thrissur" },
    { value: "Wayanad", label: "Wayanad" },
  ];

  districtLabel: string = "";
  districtValue: string = "";

  get currentPage(): number {
    return this.qsIDsService.currentPage;
  }

  get pageSize(): number {
    return this.qsIDsService.pageSize;
  }

  get totalItems(): number {
    return this.qsIDsService.totalItems;
  }


  // currentPage: number = 1;
  // pageSize: number = 30;
  // totalItems: number = 1;

  ngOnInit() {
    this.initializeBasedOnUserRole();

  }
  //variables
  MEUnitListings: any;
  spinnerForMEUnitListings : boolean = false;
  phoneNumber: string = "";

  initializeBasedOnUserRole() {
    if (this.isULB()) {
      this.getMEUnitsListings();
    } else if (this.isDistrictAdmin()) {
      this.getMEUnitsListingsForDistrictAdmin();
    } else if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.getMEUnitsListingsForStateAdmin();
    }
  }



  goToMeUnitDescription(profileID: string) {
    this.router.navigate(['/meUnitDescription'], {
      queryParams: {
        profileID: profileID,
      },
    });
  }

  //to get the details
  // getMEUnitsListings() {
  //   this.spinnerForMEUnitListings = true;
  //   const token = this.Authservice.getToken();
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   this.http
  //     .get(QS_ADMIN_URL + '/MEUnitListings', { headers }) // Changed from post to get
  //     .subscribe(
  //       (data) => {
  //         this.spinnerForMEUnitListings = false;
  //         this.MEUnitListings = data;
  //         console.log('MEUnitListings', this.MEUnitListings);
  //         // Handle success response if needed
  //       },
  //       (error) => {
  //         console.error('Error', error);
  //         if (error.status === 401) {
  //           console.error('Unauthorized - logging out');
  //           this.Authservice.logOut();
  //         } else {
  //           console.error('Error fetching MEUnitProfilePage:', error);
  //           this.spinnerForMEUnitListings = false;
  //         }
  //         // Handle error response
  //       }
  //     );
  // }

  // getMEUnitsListingsForStateAdmin() {
  //   this.spinnerForMEUnitListings = true;
  //   const token = this.Authservice.getToken();
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   this.http
  //     .get(QS_ADMIN_URL + '/MEUnitListingsForStateAdmin', { headers }) // Changed from post to get
  //     .subscribe(
  //       (data) => {
  //         this.spinnerForMEUnitListings = false;
  //         this.MEUnitListings = data;
  //         console.log('MEUnitListings', this.MEUnitListings);
  //         // Handle success response if needed
  //       },
  //       (error) => {
  //         console.error('Error', error);
  //         if (error.status === 401) {
  //           console.error('Unauthorized - logging out');
  //           this.Authservice.logOut();
  //         } else {
  //           console.error('Error fetching MEUnitProfilePage:', error);
  //           this.spinnerForMEUnitListings = false;
  //         }
  //         // Handle error response
  //       }
  //     );
  // }

  // getMEUnitsListingsForDistrictAdmin() {
  //   this.spinnerForMEUnitListings = true;
  //   const token = this.Authservice.getToken();
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   this.http
  //     .get(QS_ADMIN_URL + '/MEUnitListingsForDistrictAdmin', { headers }) // Changed from post to get
  //     .subscribe(
  //       (data) => {
  //         this.spinnerForMEUnitListings = false;
  //         this.MEUnitListings = data;
  //         console.log('MEUnitListings', this.MEUnitListings);
  //         // Handle success response if needed
  //       },
  //       (error) => {
  //         console.error('Error', error);
  //         if (error.status === 401) {
  //           console.error('Unauthorized - logging out');
  //           this.Authservice.logOut();
  //         } else {
  //           console.error('Error fetching MEUnitProfilePage:', error);
  //           this.spinnerForMEUnitListings = false;
  //         }
  //         // Handle error response
  //       }
  //     );
  // }


  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;

    console.log("districtLabel", this.districtLabel);
    console.log("districtValue", this.districtValue);
  }

  search() {}

  getMEUnitsListings() {

    if (this.phoneNumber) {
      if (this.isValidPhoneNumber(this.phoneNumber)) {
        this.districtValue = "";
        this.districtLabel = "All districts";
      } else {
        // Handle invalid phone number using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Invalid Phone Number',
          text: 'Please enter a valid 10-digit phone number.',
          confirmButtonText: 'OK'
        });
        this.spinnerForMEUnitListings = false;
        this.phoneNumber = "";
        return;
      }
    }

    
    this.spinnerForMEUnitListings = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams()
      .set('page', this.currentPage.toString())
      .set('pageSize', this.pageSize.toString())
      .set('phoneNumber', this.phoneNumber);

    this.http
      .get(QS_ADMIN_URL + '/MEUnitListings', { headers, params })
      .subscribe(
        (response: any) => {
          this.spinnerForMEUnitListings = false;
          this.MEUnitListings = response.data;
          // this.totalItems = response.total;
          this.qsIDsService.setTotalItems(response.total);
          console.log('MEUnitListings', this.MEUnitListings);
        },
        (error) => {
          // ... existing error handling ...
        }
      );
  }

  isValidPhoneNumber(phone: string): boolean {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  }

  onPhoneNumberInput(event: any) {
    const input = event.target as HTMLInputElement;
    const sanitized = input.value.replace(/[^0-9]/g, '');
    input.value = sanitized.slice(0, 10);
    this.phoneNumber = input.value;
  }

  getMEUnitsListingsForStateAdmin() {
    this.spinnerForMEUnitListings = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    if (this.phoneNumber) {
      if (this.isValidPhoneNumber(this.phoneNumber)) {
        this.districtValue = "";
        this.districtLabel = "All districts";
      } else {
        // Handle invalid phone number using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Invalid Phone Number',
          text: 'Please enter a valid 10-digit phone number.',
          confirmButtonText: 'OK'
        });
        this.spinnerForMEUnitListings = false;
        this.phoneNumber = "";
        return;
      }
    }

    

    const params = new HttpParams()
      .set('page', this.currentPage.toString())
      .set('pageSize', this.pageSize.toString())
      .set('district', this.districtValue)
      .set('phoneNumber', this.phoneNumber);

      console.log("districtValue", this.districtValue);

    this.http
      .get(QS_ADMIN_URL + '/MEUnitListingsForStateAdmin', { headers, params })
      .subscribe(
        (response: any) => {
          this.spinnerForMEUnitListings = false;
          this.MEUnitListings = response.data;
          // this.totalItems = response.total;
          this.qsIDsService.setTotalItems(response.total);
          console.log('MEUnitListings', this.MEUnitListings);
        },
        (error) => {
          // ... existing error handling ...
        }
      );
  }

  getMEUnitsListingsForDistrictAdmin() {

    
    if (this.phoneNumber) {
      if (this.isValidPhoneNumber(this.phoneNumber)) {
        this.districtValue = "";
        this.districtLabel = "All districts";
      } else {
        // Handle invalid phone number using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Invalid Phone Number',
          text: 'Please enter a valid 10-digit phone number.',
          confirmButtonText: 'OK'
        });
        this.spinnerForMEUnitListings = false;
        this.phoneNumber = "";
        return;
      }
    }


    this.spinnerForMEUnitListings = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    const params = new HttpParams()
      .set('page', this.currentPage.toString())
      .set('pageSize', this.pageSize.toString())
      .set('phoneNumber', this.phoneNumber);

    this.http
      .get(QS_ADMIN_URL + '/MEUnitListingsForDistrictAdmin', { headers, params })
      .subscribe(
        (response: any) => {
          this.spinnerForMEUnitListings = false;
          this.MEUnitListings = response.data;
          // this.totalItems = response.total;
          this.qsIDsService.setTotalItems(response.total);
          console.log('MEUnitListings', this.MEUnitListings);
        },
        (error) => {
          // ... existing error handling ...
        }
      );
  }

  onPageChange(page: number) {
    this.qsIDsService.setCurrentPage(page);
    this.initializeBasedOnUserRole();
  }

  getPaginationArray(): number[] {
    const pageCount = this.getTotalPages();
    return Array(pageCount).fill(0).map((_, index) => index + 1);
  }

  getTotalPages(): number {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ('0' + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

    //To check the user role
    isSuperAdmin(): boolean {
      return (
        this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
      );
    }
    isStateAdmin(): boolean {
      return (
        this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
      );
    }
    isDistrictAdmin(): boolean {
      return (
        this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
      );
    }
    isULB(): boolean {
      return this.Authservice.isAuthenticated() && this.Authservice.isULB();
    }
    isMEUnit(): boolean {
      return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
    }



    
}
