<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>ME Unit Description</u></p>
    </div>

    <div class="col">
      <p class="pageReference">ME Units Listings > ME Units Description</p>
    </div>
  </div>

  <button *ngIf="spinnerForMEUnitDescription" class="btn btn-primary" type="button" disabled>
    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
    <span role="status">Loading...</span>
  </button>

  <div *ngIf="!spinnerForMEUnitDescription" class="row">
    <div class="col-3 card m-2 p-4">
      <img
        class="profileImage"
        src="../../../assets/profileImage/profileImage.png"
        alt=""
      />
      <br />
      <button
        *ngIf="isULB()"
        type="button"
        (click)="passwordReset()"
        class="btn btn-outline-primary p-1"
      >
        Reset Password
      </button>
    </div>
    <div
      class="col card m-2 p-4"
      *ngFor="let MEUnitDescription of MEUnitDescription"
    >
      <div class="row">
        <div class="col">
          <p style="color: gray"><u>User Infromation</u></p>
        </div>
        <div class="col">
          <P class="MEUnitPtag"
            >ProfileID :
            <span class="MEUnitSpan">{{ MEUnitDescription.profileID }}</span></P
          >
        </div>
      </div>
      <!-------->
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >ME Unit Name :
              <span class="MEUnitSpan">{{
                MEUnitDescription.userName
              }}</span></P
            >
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >Phone Number :
              <span class="MEUnitSpan">{{ MEUnitDescription.mobile }}</span></P
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >ME APP ID :
              <span class="MEUnitSpan">{{ MEUnitDescription.meAppID }}</span></P
            >
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >CDS Registration ID :
              <span class="MEUnitSpan">{{
                MEUnitDescription.cdsRegID
              }}</span></P
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >Name :
              <span class="MEUnitSpan">{{
                MEUnitDescription.unitIncharge
              }}</span></P
            >
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >G-mail :
              <span class="MEUnitSpan">{{ MEUnitDescription.mailID }}</span></P
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >Address :
              <span class="MEUnitSpan">{{
                MEUnitDescription.address.address
              }}</span></P
            >
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >Pin code :
              <span class="MEUnitSpan">{{
                MEUnitDescription.address.pinCode
              }}</span></P
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >District :
              <span class="MEUnitSpan">{{
                MEUnitDescription.address.district
              }}</span></P
            >
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <P class="MEUnitPtag"
              >Approval Status :
              <span class="MEUnitSpan" *ngIf="!MEUnitDescription.isApproved"
                >Pending</span
              ><span class="MEUnitSpan" *ngIf="MEUnitDescription.isApproved"
                >Approved</span
              ></P
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col"></div>
        <div class="col" *ngIf="!MEUnitDescription.isApproved">
          <button
            type="button"
            (click)="giveApproval(true)"
            style="float: right"
            class="btn btn-success w-50"
          >
            Give Approval
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col" *ngIf="isULB() || isSuperAdmin()">
          <div
            *ngIf="spinnerForGivePermissions"
            class="spinner-border text-success"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            *ngIf="!spinnerForGivePermissions"
            type="button"
            (click)="permissionToEdit(true)"
            class="btn btn-success w-50"
          >
            Give Permission to edit
          </button>
        </div>

        <div class="col" *ngIf="MEUnitDescription.isApproved">
          <button
            type="button"
            (click)="giveApproval(false)"
            style="float: right"
            class="btn btn-danger w-50"
          >
            Disapprove
          </button>
        </div>
      </div>

      <!-------->
    </div>
  </div>

  <div *ngIf="!spinnerForMEUnitDescription" class="row mt-3">
    <p style="font-size: large; font-weight: bold"><u>Offered Services</u></p>
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Service</th>
            <th scope="col">Image</th>
            <th scope="col">Rate</th>
            <th scope="col">Description</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody *ngFor="let serviceList of serviceList; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ getServiceLabel(serviceList.serviceName) }}</td>
            <td>
              <div *ngFor="let imageUrl of serviceList.imageUrl">
                <img
                
                  [src]="imageUrl"
                  alt="image name"
                  style="max-width: 100px; max-height: 100px; border-radius: 10px; margin-bottom: 5px;"
                />
              </div>
            </td>
            <td>{{ serviceList.ratePerHour }} / {{serviceList.rupeesUnit}}</td>
            <td>{{ serviceList.serviceDescription }}</td>
            <td>
              <button
                (click)="openEditModal(serviceList)"
                type="button"
                class="btn btn-primary"
              >
                Edit
              </button>
            </td>
            <td>
              <div
                *ngIf="spinnerForDeleteService"
                class="spinner-border text-danger"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <button
                *ngIf="!spinnerForDeleteService"
                (click)="deleteServiceForMEunit(serviceList._id)"
                type="button"
                class="btn btn-outline-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="!spinnerForAllServiceProviders" class="row mt-3">
    <p style="font-size: large; font-weight: bold"><u>All Service Providers</u></p>
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Reg Status</th>

            <th scope="col">Account Status</th>
            <th scope="col">Service</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody
          *ngFor="
            let serviceProviderListing of allServiceProviders;
            let i = index
          "
        >
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ serviceProviderListing.username }}</td>
            <td>
              <i
                class="fa-solid fa-phone"
                *ngIf="serviceProviderListing.primaryMobileNumber"
              ></i>
              {{ serviceProviderListing.primaryMobileNumber }}
            </td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUTCToIST(serviceProviderListing.registeredDate) }}
            </td>
            <td>
              <span
                [class.active]="serviceProviderListing.active"
                [class.inactive]="!serviceProviderListing.active"
                >{{
                  serviceProviderListing.active ? "Active" : "Inactive"
                }}</span
              >
            </td>

            <td>
              <span [ngClass]="{
                'status-activeStatus': !serviceProviderListing.delete,
                'status-deletedStatus': serviceProviderListing.delete
              }">
                {{ serviceProviderListing.delete ? 'Deleted' : 'Active' }}
              </span>
            </td>
            <td>
              <div *ngFor="let name of serviceProviderListing.serviceName">
                <p style="margin-top: 0" class="serviceName">
                  {{ getServiceLabel(name) }}
                </p>
              </div>

              <!-- {{ getServiceLabel(serviceProviderListing.serviceName) }}</td> -->
            </td>

            <td>
              <button
                type="button"
                (click)="
                  goToServiceProviderDetails(serviceProviderListing.profileID)
                "
                class="btn btn-link"
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <div *ngIf="spinnerForAllServiceProviders" class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>


  <!-- Add this modal structure to your component's template -->
  <!-- Add this modal structure to your component's template -->
  <div
    class="modal fade"
    id="editServiceModal"
    tabindex="-1"
    aria-labelledby="editServiceModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editServiceModalLabel">Edit Service</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="ratePerHour" class="form-label">Rate per Hour</label>
                  <input
                    type="text"
                    class="form-control"
                    id="ratePerHour"
                    [(ngModel)]="selectedService.ratePerHour"
                    name="ratePerHour"
                    required
                    maxlength="6"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="numberOnly($event)"
                    
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="rupeesUnit" class="form-label">Unit</label>
                  <select
                    [(ngModel)]="selectedService.rupeesUnit"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="rupeesUnit"
                  >
                    <option value="" disabled selected>
                      Please select the unit
                    </option>
                    <option value="hour">Hour</option>
                    <option value="day">Day</option>
                    <option value="month">Month</option>
                  </select>
                </div>
              </div>
            </div>
            
            <div class="mb-3">
              <label for="serviceDescription" class="form-label">Description</label>
              <textarea
                class="form-control"
                id="serviceDescription"
                [(ngModel)]="selectedService.serviceDescription"
                name="serviceDescription"
                required
                maxlength="100"
              ></textarea>
            </div>


            

             <!-- Image Upload and Preview Section -->
           <!-- Image Upload and Preview Section -->
           <div class="row">
            <div class="col-md-4" *ngFor="let image of imageUrls; let i = index">
              <div class="mb-3">
                <label [for]="'imageUpload' + i" class="form-label">Image {{i + 1}}</label>
                <input
                  type="file"
                  class="form-control"
                  [id]="'imageUpload' + i"
                  (change)="onFileChange($event, i)"
                  accept="image/*"
                />
              </div>
              <img
                [src]="image"
                *ngIf="image"
                class="img-fluid mb-2"
                style="max-height: 100px; border-radius: 5px;"
                [alt]="'Image ' + (i + 1)"
              />
            </div>
          </div>




            <button type="submit" class="btn btn-primary">Save changes</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
