import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Route, Router, ActivatedRoute } from "@angular/router";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import Swal from "sweetalert2";
import { QsIDsService } from "../../services/qs-ids.service";
import { environment } from "src/environments/environment";
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: "app-service-request-description",
  templateUrl: "./service-request-description.component.html",
  styleUrls: ["./service-request-description.component.css"],
})
export class ServiceRequestDescriptionComponent implements OnInit {
  orderRequestID: string;
  orderResult: any;
  serviceProviderList: any;
  selectedProvider: any[] = [];
  selectedProviderDisplayText: string = "";
  remarks: string = "";
  amountForMEUnit: number = null;
  amountForServiceProvider: any;
  showPopup = false;
  AllMEUnitDropdown: any;
  selectedMEUnit: string = "";
  showAdvancedPaymentPopUp = false;
  showUpdatePaymentDetailsPopUp = false;
  advancePaymentMethod: string;
  advancePayment: string;
  advancePaymentTransactionID: string = "";
  selectServiceProviderPopUp: boolean = false;
  spinnerForAdvancedPayment: boolean = false;
  spinnerForPaymentDetails: boolean = false;
  spinnerForOrderCompletion: boolean = false;
  showPopupForCancelOrder: boolean = false;

  spinnerForAddServiceProvider: boolean = false;

  refundAmount: string = "";
  editServiceOrderClosure: boolean = false;

  serviceProviderPopUp() {
    this.selectServiceProviderPopUp = true;
  }

  onEditServiceOrderClosure() {
    this.editServiceOrderClosure = !this.editServiceOrderClosure;
  }
  closeselectServiceProviderPopUp() {
    this.selectServiceProviderPopUp = false;
    this.selectedProvider = [];
    this.selectedProviderDisplayText = "";
  }

  constructor(
    private location: Location,
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private QsIDsService: QsIDsService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.orderRequestID = queryParams["requestID"];
      console.log("orderRequestID :", this.orderRequestID);
    });

    // this.getServiceProviderList();

    if (this.orderRequestID) {
      this.getServiceRequestDescription();
    }
  }

  transferServicePopUp() {
    this.showPopup = true;
    this.getAllMEUnit();
  }

  closeCloseOrderPopUp() {
    this.showPopupForCancelOrder = false;
  }

  cancelOrderRequest() {
    if (this.refundAmount > this.orderResult[0].totalServicePrice) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "The refund amount is greater than the paid amount",
      });
      return;
    }
    this.showPopupForCancelOrder = false;
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to cancel this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        console.log("The order function is called");

        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );
        let params = new HttpParams().set("orderID", this.orderRequestID);
        // .set("refundAmount", this.refundAmount);

        if (this.refundAmount) {
          params = params.set("refundAmount", this.refundAmount);
        }

        console.log("headers", headers);
        console.log("params", params);

        const options = { headers, params };
        this.http
          .put(QS_ADMIN_URL + "/cancelAcceptedOrders", {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire("Success!", "Order cancelled successfully.", "success");
              this.getServiceRequestDescription();
            },
            (error) => {
              console.log(error);
              Swal.fire(
                "Error!",
                "There was a problem cancelling the order.",
                "error"
              );
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              } else {
                console.error("Error fetching MEUnitProfilePage:", error);
              }
            }
          );
      }
    });
  }

  closePopup() {
    this.showPopup = false;
    this.selectedMEUnit = "";
    this.showUpdatePaymentDetailsPopUp = false;
    this.changeDate = false;
  }

  addAdvancedPayment() {
    this.showAdvancedPaymentPopUp = true;
  }

  updatePaymentDetails() {
    this.showUpdatePaymentDetailsPopUp = true;
  }

  paymentMethods: string = "";
  totalAmount: number | undefined;
  hoursWorked: number | undefined;
  setPaymentMethod(method: string) {
    this.paymentMethods = method;
    console.log("Payment method set to:", this.paymentMethods);
  }

  saveUpdatedPaymentDetails() {
    if (!this.paymentMethods || !this.totalAmount) {
      Swal.fire({
        icon: "warning",
        title: "Please choose the update status",
        text: "You need to select a status before confirming the order update.",
      });
      return; // Exit the method if updateStatus is not set
    }

    Swal.fire({
      title: "Confirm Order update",
      text: "Are you sure you want to update this service?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, mark as update",
      confirmButtonColor: "#218b82",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerForPaymentDetails = true;
        const token = this.Authservice.getToken();

        console.log("the order function is called");
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );
        const requestBody = {
          updateStatus: "COM",
          paymentMethods: this.paymentMethods,
          totalAmount: this.totalAmount,
          hoursWorked: this.hoursWorked,
        };

        // If the user confirms, proceed with the HTTP request
        this.http
          .put(
            QS_ADMIN_URL + `/saveUpdatedPaymentDetails/${this.orderRequestID}`,
            requestBody,
            { headers }
          )
          .subscribe(
            (response: any) => {
              console.log("Backend response:", response);
              Swal.fire("Success", response["message"], "success");
              this.getServiceRequestDescription();
              this.showUpdatePaymentDetailsPopUp = false;
              this.spinnerForPaymentDetails = false;
            },
            (error) => {
              console.error("Update error:", error);
              Swal.fire("Error", "Failed to update order", "error");
              // Handle error responses or UI updates for errors
              this.spinnerForPaymentDetails = false;
            }
          );
      }
    });
  }

  closeAdvancedPaymentPopUp() {
    this.showAdvancedPaymentPopUp = false;
    this.showUpdatePaymentDetailsPopUp = false;
    this.clearAdvancedPaymentFields();
  }

  clearAdvancedPaymentFields() {
    this.advancePayment = "";
    this.advancePaymentMethod = "";
    this.advancePaymentTransactionID = "";
  }
  addAdvancedPaymentSubmit() {
    if (!this.advancePayment) {
      Swal.fire("error!", "Please enter the payment amount ");
      return;
    }

    if (!this.advancePaymentMethod) {
      Swal.fire("error!", "Please choose a payment method");
      return;
    }

    if (
      this.advancePaymentMethod == "UPI" &&
      !this.advancePaymentTransactionID
    ) {
      Swal.fire("error!", "Please enter the transaction ID ");
      return;
    }

    if (this.advancePayment) {
      this.spinnerForAdvancedPayment = true;
      const token = this.Authservice.getToken();

      console.log("the order function is called");
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("advancePayment", this.advancePayment)
        .set("advancePaymentMethod", this.advancePaymentMethod)
        .set("advancePaymentTransactionID", this.advancePaymentTransactionID)
        .set("orderID", this.orderRequestID);

      console.log("headers", headers);
      console.log("params", params);
      const options = { headers, params };
      this.http
        .put(QS_ADMIN_URL + "/addAdvancedPaymentSubmit", {}, options)
        .subscribe(
          (data: any) => {
            Swal.fire("Success!", "Payment marked as successful", "success");
            this.showAdvancedPaymentPopUp = false;
            this.getServiceRequestDescription();
            this.clearAdvancedPaymentFields();
            this.spinnerForAdvancedPayment = false;
          },
          (error) => {
            console.log(error);
            Swal.fire(
              "Error!",
              "There was a problem adding the service provider.",
              "error"
            );
            this.clearAdvancedPaymentFields();
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
              this.spinnerForAdvancedPayment = false;
            }
          }
        );
    } else {
      Swal.fire("error!", "Please select a ME Unit", "error");
    }
  }

  transferServiceOrderSubmit() {
    if (this.selectedMEUnit) {
      const token = this.Authservice.getToken();

      console.log("the order function is called");
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("selectedMEUnit", this.selectedMEUnit)
        .set("orderID", this.orderRequestID);

      console.log("headers", headers);
      console.log("params", params);
      const options = { headers, params };
      this.http
        .put(QS_ADMIN_URL + "/transferServiceOrder", {}, options)
        .subscribe(
          (data: any) => {
            Swal.fire(
              "Success!",
              "service order transferred successfully.",
              "success"
            );
            this.router.navigate(["/serviceRequestListings"]);
          },
          (error) => {
            console.log(error);
            Swal.fire(
              "Error!",
              "There was a problem adding the service provider.",
              "error"
            );
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
          }
        );
    } else {
      Swal.fire("error!", "Please select a ME Unit", "error");
    }
  }

  getAllMEUnit() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + "/getAllMEUnitForServiceChange", { headers })
      .subscribe(
        (data) => {
          console.log(data);
          this.AllMEUnitDropdown = data;

          console.log("the allMEUnit dropdown", this.AllMEUnitDropdown);

          // Handle success response if needed
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
          }
          // Handle error response
        }
      );
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  getOrderStatus(
    orderStatus: string
  ):
    | "PENDING"
    | "COMPLETED"
    | "ACCEPTED"
    | "CANCELLED"
    | "PROCESSING"
    | "ASSIGNED"
    | "UNKNOWN" {
    if (orderStatus == "PEN") {
      return "PENDING";
    } else if (orderStatus == "COM") {
      return "COMPLETED";
    } else if (orderStatus == "ACC") {
      return "ACCEPTED";
    } else if (orderStatus == "CAN") {
      return "CANCELLED";
    } else if (orderStatus == "PRO") {
      return "PROCESSING";
    } else if (orderStatus == "ASS") {
      return "ASSIGNED";
    } else {
      return "UNKNOWN";
    }
  }

  getPaymentStatus(paymentStatus: string): "Not Paid" | "PAID" | "UNKNOWN" {
    if (paymentStatus == "NPD") {
      return "Not Paid";
    } else if (paymentStatus == "PAID") {
      return "PAID";
    } else {
      return "UNKNOWN";
    }
  }

  phoneNumber: any;
  getServiceRequestDescription() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("orderID", this.orderRequestID);
    this.http
      .get(QS_ADMIN_URL + "/serviceRequestDescription", { headers, params })
      .subscribe(
        (data) => {
          console.log(data);
          this.orderResult = data;
          this.remarks = this.orderResult[0].orderRemarks;
          this.amountForMEUnit = this.orderResult[0].amountForMEUnit;

          this.phoneNumber = this.orderResult[0].address.deliveryNumber;

          console.log("the phone number is", this.phoneNumber);
          this.getServiceProviderList();

          // Handle success response if needed
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
          }
          // Handle error response
        }
      );
  }


  // remove assigned service provider
  removeAssignedServiceProvider(serviceProviderProfileID: string) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove this service provider?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set("orderID", this.orderRequestID)
          .set("serviceProviderProfileID", serviceProviderProfileID);

        this.http
          .delete(QS_ADMIN_URL + "/removeAssignedServiceProvider", {
            headers,
            params,
          })
          .subscribe(
            (data: any) => {
              Swal.fire(
                "Removed!",
                "Service provider has been removed.",
                "success"
              );
              this.getServiceRequestDescription(); // Refresh the order details
            },
            (error) => {
              console.error("Error", error);
              Swal.fire(
                "Error!",
                "There was a problem removing the service provider.",
                "error"
              );
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              }
            }
          );
      }
    });
  }


  getServiceProviderList() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

    this.http
      .get(QS_ADMIN_URL + "/getServiceProviderList", { headers })
      .subscribe(
        (data: any[]) => {
          // Check if orderResult and serviceProvider exist before accessing them
          if (this.orderResult[0] && this.orderResult[0].serviceProvider) {
            const assignedProfileIDs = this.orderResult[0].serviceProvider.map(
              (sp) => sp.ProfileID
            );

            // Filter out service providers that have already been assigned
            this.serviceProviderList = data.filter(
              (provider) => !assignedProfileIDs.includes(provider.profileID)
            );

            console.log(
              this.serviceProviderList,
              "filtered serviceProviderList"
            );
          } else {
            console.error("orderResult or serviceProvider is undefined");
            // Handle the case where serviceProvider is undefined
            this.serviceProviderList = data; // Show all providers if no assignments found
          }
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
          }
        }
      );
  }

  toggleProviderSelection(provider: any): void {
    const index = this.selectedProvider.findIndex(
      (p) => p._id === provider._id
    );
    if (index === -1) {
      this.selectedProvider.push(provider);
    } else {
      this.selectedProvider.splice(index, 1);
    }
    this.updateDisplayText();
    console.log("Selected Providers:", this.selectedProvider);
  }

  isSelected(provider: any): boolean {
    return this.selectedProvider.some((p) => p._id === provider._id);
  }

  updateDisplayText(): void {
    if (this.selectedProvider.length > 0) {
      const providerNames = this.selectedProvider.map(
        (provider) => provider.username
      );
      this.selectedProviderDisplayText = providerNames.join(", ");
    } else {
      this.selectedProviderDisplayText = "";
    }
  }

  goBack() {
    this.location.back();
  }

  addServiceProvider() {
    this.spinnerForAddServiceProvider = true;
    if (this.selectedProvider && this.selectedProvider.length > 0) {
      const token = this.Authservice.getToken();

      console.log("the order function is called");
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      // const params = new HttpParams()
      //   .set('orderID', this.orderRequestID);

      const requestBody = {
        selectedProviders: this.selectedProvider,
        orderID: this.orderRequestID,
        phoneNumber: this.phoneNumber,
      };

      console.log("headers", headers);

      const options = { headers };
      this.http
        .put(QS_ADMIN_URL + "/qsAddServiceProvider", requestBody, options)
        .subscribe(
          (data: any) => {
            Swal.fire(
              "Success!",
              "Service provider assigned successfully.",
              "success"
            );
            this.getServiceRequestDescription();
            this.closeselectServiceProviderPopUp();
            this.spinnerForAddServiceProvider = false;
          },
          (error) => {
            console.log(error);
            Swal.fire(
              "Error!",
              "There was a problem adding the service provider.",
              "error"
            );
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
              this.closeselectServiceProviderPopUp();
              this.spinnerForAddServiceProvider = false;
            }
          }
        );
    } else {
      Swal.fire("error!", "Please select a service provider", "error");
      this.closeselectServiceProviderPopUp();
    }
  }

  cancelOrder() {
    this.showPopupForCancelOrder = true;
  }

  orderStatusProgress() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        console.log("The order function is called");

        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set("orderID", this.orderRequestID)
          .set("phoneNumber", this.phoneNumber);

        console.log("headers", headers);
        console.log("params", params);

        const options = { headers, params };
        this.http
          .put(QS_ADMIN_URL + "/orderStatusProgress", {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire("Success!", "Order status updated.", "success");
              this.getServiceRequestDescription();
            },
            (error) => {
              console.log(error);
              Swal.fire("Error!", "There was a problem.", "error");
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              } else {
                console.error("Error fetching MEUnitProfilePage:", error);
              }
            }
          );
      }
    });
  }

  serviceOrderCompletion() {
    if (this.orderResult[0].serviceProvider) {
      const serviceProviders = this.orderResult[0].serviceProvider.map(
        (provider) => ({
          ProfileID: provider.ProfileID,
          amount: provider.serviceAmount,
        })
      );
      this.amountForServiceProvider = serviceProviders;
      console.log("Service Providers:", serviceProviders);
      console.log(
        "this.amountForServiceProvider:",
        this.amountForServiceProvider
      );
    } else {
      console.log("No service providers found");
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerForOrderCompletion = true;
        const token = this.Authservice.getToken();
        console.log("The order function is called");

        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set("orderID", this.orderRequestID)
          .set("remarks", this.remarks)
          .set("amountForMEUnit", this.amountForMEUnit)
          .set(
            "amountForServiceProvider",
            JSON.stringify(this.amountForServiceProvider)
          );

        console.log("headers", headers);
        console.log("params", params);

        const options = { headers, params };
        this.http
          .put(QS_ADMIN_URL + "/serviceOrderCompletion", {}, options)
          .subscribe(
            (data: any) => {
              this.spinnerForOrderCompletion = false;
              Swal.fire("Success!");
              this.getServiceRequestDescription();
              this.clearServiceOrderClosure();
            },
            (error) => {
              console.log(error);
              Swal.fire(
                "Error!",
                "There was a problem cancelling the order.",
                "error"
              );
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              } else {
                console.error("Error fetching MEUnitProfilePage:", error);
                this.spinnerForOrderCompletion = false;
              }
            }
          );
      }
    });
  }

  clearServiceOrderClosure() {
    this.remarks = "";
    this.amountForMEUnit = null;
    this.amountForServiceProvider = null;
  }

  validateNumberInput(event: any) {
    const input = event.target;
    const originalValue = input.value;
    input.value = input.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (input.value.length > 5) {
      input.value = input.value.slice(0, 5); // Ensure max length of 4 digits
    }
    if (originalValue !== input.value) {
      event.stopImmediatePropagation(); // Stop further propagation of the current event
    }
  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ("0" + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ("0" + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

  //--to edit the date---------------------------------------------------
  //- the variable
  changeDate: boolean = false; // to open the model window to edit the date
  startDate: Date; // to store the start date
  endDate: Date; // to store the end date

  //--to open the model window to edit the date
  editDate() {
    if (this.orderResult[0].orderStatus === "COM") {
      Swal.fire({
        icon: "warning",
        title: "Order is already completed",
        text: "You cannot edit the date of a completed order.",
      });
      return;
    }

    if (
      this.orderResult[0].serviceProvider &&
      this.orderResult[0].serviceProvider.length > 0
    ) {
      Swal.fire({
        icon: "warning",
        title: "Service provider is already assigned",
        text: "Please remove the service provider first.",
      });
      return;
    }

    this.changeDate = true;
  }

  //--to get the current date
  getCurrentDate(): string {
    return new Date().toISOString().split("T")[0];
  }

  //--to update the start date
  updateStartOrderForDate(newDate: string) {
    console.log("Updating order date to:", newDate);
    this.startDate = new Date(newDate);
    console.log("the start date is", this.startDate);
  }

  //--to update the end date
  updateEndOrderForDate(newDate: string) {
    console.log("Updating order date to:", newDate);
    this.endDate = new Date(newDate);
    console.log("the end date is", this.endDate);
  }
  //--to update the date
  updateTheDate() {
    //--to check if the start date is empty
    if (!this.startDate) {
      Swal.fire({
        icon: "warning",
        title: "Start Date Required",
        text: "Please choose a start date.",
      });
      return;
    }

    //--to check if the end date is empty
    if (this.orderResult[0].endDate && !this.endDate) {
      Swal.fire({
        icon: "warning",
        title: "End Date Required",
        text: "Please choose a end date.",
      });
      return;
    }

    //--to check if the start date is greater than the end date
    if (this.startDate > this.endDate) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Date Range",
        text: "The start date cannot be greater than the end date.",
      });
      return;
    }

    //--to update the date
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    let params = new HttpParams()
      .set("orderID", this.orderRequestID)
      .set("startDate", this.startDate.toISOString());
    // .set("endDate", this.endDate.toISOString());

    if (this.endDate) {
      params = params.set("endDate", this.endDate.toISOString());
    }

    const options = { headers, params };
    this.http
      .put(QS_ADMIN_URL + "/updateServiceOrderDate", {}, options)
      .subscribe(
        (data: any) => {
          Swal.fire("Success!", "Service date updated.", "success");
          this.getServiceRequestDescription();
          this.changeDate = false;
        },
        (error) => {
          console.log(error);
          Swal.fire(
            "Error!",
            error.error.message ||
              "There was a problem updating the order date.",
            "error"
          );
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
          }
        }
      );
  }
}
