import { Component, OnInit } from "@angular/core";
import { Route, Router, ActivatedRoute } from "@angular/router";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import { PasswordService } from "../password.service";
import Swal from "sweetalert2";
import { QsIDsService } from "../../services/qs-ids.service";
import { environment } from "src/environments/environment";
const QS_ADMIN_URL = environment.qsAdminUrl;
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

declare var bootstrap: any;

@Component({
  selector: "app-me-unit-description",
  templateUrl: "./me-unit-description.component.html",
  styleUrls: ["./me-unit-description.component.css"],
})
export class MeUnitDescriptionComponent implements OnInit {
  //variable
  profileID: string = "";
  MEUnitDescription: any;
  keyword: string = "";
  userAccount: string = "";
  serviceList: any;
  spinnerForMEUnitDescription: boolean = false;

  spinnerForGivePermissions: boolean = false;

  allServiceProviders: any[] = [];
  spinnerForAllServiceProviders: boolean = false;

  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private passwordService: PasswordService,
    private QsIDsService: QsIDsService,
    private sanitizer: DomSanitizer
  ) {}

  newImages: File[] = [];
  // onFileChange(event: any, index: number) {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const file = event.target.files[0];

  //     // Check if the file is an image
  //     if (!file.type.startsWith('image/')) {
  //       Swal.fire({
  //         title: 'Error',
  //         text: 'Please upload only image files.',
  //         icon: 'error',
  //         confirmButtonColor: '#3085d6',
  //       });
  //       return;
  //     }

  //     this.newImages[index] = file;
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.imageUrls[index] = e.target.result;
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // }

  async onFileChange(event: any, index: number) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Check if the file is an image
      if (!file.type.startsWith("image/")) {
        Swal.fire({
          title: "Error",
          text: "Please upload only image files.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      // Check if the file size is greater than 5 MB
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        Swal.fire({
          title: "Warning",
          text: "The selected image is larger than 5 MB.",
          icon: "warning",
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      try {
        const compressedFile = await this.compressImage(file, index);
        this.compressedImages[index] = compressedFile;
        const imageUrl = URL.createObjectURL(compressedFile);
        this.imageUrls[index] = imageUrl;
      } catch (error) {
        console.error("Image compression error:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to process the image. Please try again.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      }
    }
  }

  async compressImage(file: File, index: number): Promise<File> {
    console.log('the selected service name is', this.selectedService.serviceName);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d")!;
          const scaleFactor = 0.7;
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File(
                  [blob],
                  `${this.profileID}${this.selectedService.serviceName}QSImage${
                    index + 1
                  }.jpg`,
                  {
                    type: "image/jpeg",
                    lastModified: Date.now(),
                  }
                );
                resolve(compressedFile);
              } else {
                reject(new Error("Failed to compress image"));
              }
            },
            "image/jpeg",
            0.7
          );
        };
        img.src = event.target.result;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.profileID = queryParams["profileID"];
      console.log("Profile ID:", this.profileID);
    });

    if (this.profileID) {
      this.getMEUnitDescription();
      this.getAllServiceProviders();
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    }
    return true;
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  // getServiceLabel(serviceName: string): string {
  //   switch (serviceName) {
  //     case 'qs_ID_01':
  //       return 'Driver';
  //     case 'qs_ID_02':
  //       return 'Plumber';
  //     // Add more cases for other serviceNames as needed
  //     default:
  //       return 'Unknown'; // Default case if serviceName doesn't match expected values
  //   }
  // }

  //to get the details
  getMEUnitDescription() {
    this.spinnerForMEUnitDescription = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("profileID", this.profileID);
    this.http
      .get(QS_ADMIN_URL + "/MEUnitDescription", { headers, params })
      .subscribe(
        (data) => {
          this.spinnerForMEUnitDescription = false;
          this.MEUnitDescription = data;
          console.log("MEUnitDescription", this.MEUnitDescription);
          this.userAccount = this.MEUnitDescription[0].userAccount;
          this.serviceList = this.MEUnitDescription[0].services;
          console.log("the service list", this.serviceList);
          // Handle success response if needed
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
            this.spinnerForMEUnitDescription = false;
          }
          // Handle error response
        }
      );
  }

  getAllServiceProviders() {
    this.spinnerForAllServiceProviders = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("profileID", this.profileID);

    this.http
      .get(QS_ADMIN_URL + "/getAllServiceProvidersForMEUnit", {
        headers,
        params,
      })
      .subscribe(
        (data: any) => {
          this.spinnerForAllServiceProviders = false;
          this.allServiceProviders = data;
          console.log("All Service Providers:", this.allServiceProviders);
          // Handle success response if needed
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching all service providers:", error);
            this.spinnerForAllServiceProviders = false;
          }
          // Handle error response
        }
      );
  }
  giveApproval(approvalStatus: boolean) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("profileID", this.profileID);

    const updatedData = {
      isApproved: approvalStatus,
    };

    console.log(updatedData, this.profileID);

    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If user clicks OK, execute the API request
        this.http
          .put(QS_ADMIN_URL + "/MEUnitApprove", updatedData, {
            headers,
            params,
          })
          .subscribe(
            (response) => {
              console.log("Update successful", response);
              // Handle success response if needed
              Swal.fire("Success!");
              this.getMEUnitDescription();
            },
            (error) => {
              console.error("Error", error);
              // Handle error response
              Swal.fire("Error!", "Failed to update", "error");
            }
          );
      }
    });
  }

  goToServiceProviderDetails(profileID: string) {
    this.router.navigate(["/serviceProviderDescription"], {
      queryParams: {
        profileID: profileID,
      },
    });
  }

  permissionToEdit(permissionStatus: boolean) {
    console.log("Permission to edit", permissionStatus);
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("profileID", this.profileID);

    const updatedData = {
      permissionStatus: permissionStatus,
    };

    console.log(updatedData, this.profileID);

    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerForGivePermissions = true;
        // If user clicks OK, execute the API request
        this.http
          .put(QS_ADMIN_URL + "/MEUnitPermissionToEdit", updatedData, {
            headers,
            params,
          })
          .subscribe(
            (response) => {
              console.log("Update successful", response);
              // Handle success response if needed
              Swal.fire("Success!");
              this.getMEUnitDescription();
              this.spinnerForGivePermissions = false;
            },
            (error) => {
              console.error("Error", error);
              this.spinnerForGivePermissions = false;
              // Handle error response
              Swal.fire("Error!", "Failed to update", "error");
            }
          );
      }
    });
  }

  spinnerForDeleteService: boolean = false;

  deleteServiceForMEunit(ID: string) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("profileID", this.profileID);

    const updatedData = {
      serviceID: ID,
    };

    console.log(updatedData, this.profileID);

    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerForDeleteService = true;
        // If user clicks OK, execute the API request
        this.http
          .put(QS_ADMIN_URL + "/MEUnitDeleteService", updatedData, {
            headers,
            params,
          })
          .subscribe(
            (response) => {
              console.log("Update successful", response);
              // Handle success response if needed
              Swal.fire("Success!");
              this.getMEUnitDescription();
              this.spinnerForDeleteService = false;
            },
            (error) => {
              console.error("Error", error);
              this.spinnerForDeleteService = false;
              // Handle error response
              Swal.fire("Error!", "Failed to update", "error");
            }
          );
      }
    });
  }

  passwordReset() {
    const newPassword = this.passwordService.generateRandomPassword();
    const passwordReset = newPassword;
    const userAccount = this.userAccount;

    Swal.fire({
      title: "Are you sure you want to reset the password?",
      text: "The new password is: " + passwordReset,
      icon: "question",
      showCancelButton: true, // Display the cancel button
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel", // Text for the cancel button
      confirmButtonColor: "rgb(38 117 79)",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set("newKeyword", passwordReset)
          .set("userAccount", userAccount);

        const options = { headers, params };
        this.http
          .put(QS_ADMIN_URL + "/passwordResetMEUnit", {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              } else {
                console.error("Error fetching MEUnitProfilePage:", error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Password reset cancelled", "", "info");
      }
    });
  }
  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
  isULB(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isULB();
  }
  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }

  selectedService: any = {};
  changedPrice: number;
  changedDescription: string;
  imageUrls: (string | ArrayBuffer | null)[] = [null, null, null];
  compressedImages: (File | null)[] = [null, null, null];

  openEditModal(service: any) {
    this.selectedService = { ...service };
    // this.newImages = [null, null, null];
    this.compressedImages = [null, null, null];

    this.imageUrls = [...(service.imageUrl || []), null, null, null].slice(
      0,
      3
    );
    const modal = new bootstrap.Modal(
      document.getElementById("editServiceModal")
    );
    modal.show();
  }

  // onSubmit() {
  //   this.changedPrice = this.selectedService.ratePerHour;
  //   this.changedDescription = this.selectedService.serviceDescription;
  //   const rupeesUnit = this.selectedService.rupeesUnit;
  //   const ID = this.selectedService._id;
  //   console.log('Updated Price:', this.changedPrice);
  //   console.log('Updated Description:', this.changedDescription);
  //   console.log('Updated rupeesUnit:', rupeesUnit);

  //   console.log('the database id is', ID);

  //   // Update the service in the serviceList array (optional)
  //   const index = this.serviceList.findIndex(
  //     (service) => service._id === this.selectedService._id
  //   );
  //   if (index !== -1) {
  //     this.serviceList[index].ratePerHour = this.changedPrice;
  //     this.serviceList[index].serviceDescription = this.changedDescription;
  //     this.serviceList[index].rupeesUnit = rupeesUnit;
  //   }

  //   const token = this.Authservice.getToken();
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //   const params = new HttpParams().set('profileID', this.profileID);

  //   const updatedData = {
  //     serviceID: ID,
  //     changedPrice: this.changedPrice,
  //     changedDescription: this.changedDescription,
  //     rupeesUnit: rupeesUnit,
  //   };

  //   console.log(updatedData, this.profileID);

  //   // Display SweetAlert confirmation dialog
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Do you want to edit this service?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, update it!',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // If user clicks OK, execute the API request
  //       this.http
  //         .put(QS_ADMIN_URL + '/MEUnitUpdateService', updatedData, {
  //           headers,
  //           params,
  //         })
  //         .subscribe(
  //           (response) => {
  //             console.log('Update successful', response);
  //             // Handle success response if needed
  //             Swal.fire('Success!');
  //             this.getMEUnitDescription();
  //           },
  //           (error) => {
  //             console.error('Error', error);

  //             // Handle error response
  //             Swal.fire('Error!', 'Failed to update', 'error');
  //           }
  //         );
  //     }
  //   });

  //   // Close the modal
  //   const modal = bootstrap.Modal.getInstance(
  //     document.getElementById('editServiceModal')
  //   );
  //   modal.hide();
  // }

  onSubmit() {
    console.log("Selected service:", this.selectedService);

    const formData = new FormData();

    // Append text data
    formData.append("serviceID", this.selectedService._id);
    formData.append(
      "changedPrice",
      this.selectedService.ratePerHour.toString()
    );
    formData.append(
      "changedDescription",
      this.selectedService.serviceDescription
    );
    formData.append("rupeesUnit", this.selectedService.rupeesUnit);

    console.log('the compressed images are', this.compressedImages);

  

    // Append image files
    this.compressedImages.forEach((file, index) => {
      if (file) {
        formData.append(`image${index + 1}`, file, file.name);
      }
    });

    console.log("Submitting form data:", formData);
    console.log("Selected service:", this.selectedService);
    console.log("Compressed images:", this.compressedImages);

    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to edit this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If user clicks OK, execute the API request
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );

        this.http
          .put(
            `${QS_ADMIN_URL}/MEUnitUpdateService?profileID=${this.profileID}`,
            formData,
            { headers }
          )
          .subscribe(
            (response) => {
              console.log("Update successful", response);
              Swal.fire("Success!", "Service updated successfully", "success");
              this.getMEUnitDescription();
              this.closeEditModal();
            },
            (error) => {
              console.error("Error", error);
              Swal.fire("Error!", "Failed to update service", "error");
            }
          );
      }
    });
  }

  closeEditModal() {
    const modal = bootstrap.Modal.getInstance(
      document.getElementById("editServiceModal")
    );
    modal.hide();
  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ("0" + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ("0" + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }
}
