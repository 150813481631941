<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!-------------------------------------------------------------------------->
  <div class="popup-overlay" *ngIf="showPopup" (click)="closePopup()"></div>
  <div class="popup" *ngIf="showPopup">
    <div class="col">
      <p>To transfer this service from one MEUnit to another one</p>
      <div class="mb-3">
        <label for="phoneNumber" class="form-label">Select MEUnit</label>
        <select
          class="form-select"
          aria-label="Service select"
          [(ngModel)]="selectedMEUnit"
          name="selectedMEUnit"
        >
          <option disabled selected>Choose a MEUnit</option>
          <option
            style="margin: 50px"
            *ngFor="let unit of AllMEUnitDropdown"
            [value]="unit.profileID"
          >
            {{ unit.userName }}
          </option>
        </select>
      </div>
    </div>
    <br />
    <button (click)="closePopup()" type="button" class="btn btn-secondary">
      Cancel
    </button>
    <button
      (click)="transferServiceOrderSubmit()"
      style="float: right"
      type="button"
      class="btn btn-success"
    >
      Submit
    </button>
  </div>
  <!-------------------------------------------------------------------------->

  <div class="popup-overlay" *ngIf="changeDate" (click)="closePopup()"></div>
  <div class="popup" *ngIf="changeDate">
    <div class="col">
      <p>To change the date of service</p>

      <div class="row">
        <div class="col">
          <label for="">Start Date</label>
          <input
            type="date"
            id="dateInput"
            class="form-control"
            aria-describedby="dateInputHelp"
            [ngModel]="
              orderResult?.orderForDate
                ? convertUTCToIST(orderResult.orderForDate)
                : null
            "
            (ngModelChange)="updateStartOrderForDate($event)"
            [min]="getCurrentDate()"
          />
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="">End Date</label>
            <input
              type="date"
              id="dateInput"
              class="form-control"
              aria-describedby="dateInputHelp"
              [ngModel]="
                orderResult?.orderForDate
                  ? convertUTCToIST(orderResult.orderForDate)
                  : null
              "
              (ngModelChange)="updateEndOrderForDate($event)"
              [min]="getCurrentDate()"
            />
          </div>
        </div>
      </div>
    </div>
    <br />
    <button (click)="closePopup()" type="button" class="btn btn-secondary">
      Cancel
    </button>
    <button
      (click)="updateTheDate()"
      style="float: right"
      type="button"
      class="btn btn-success"
    >
      Submit
    </button>
  </div>

  <!-------------------------------------------------------------------------->
  <div
    class="popup-overlay"
    *ngIf="showPopupForCancelOrder"
    (click)="closePopup()"
  ></div>
  <div class="popup" *ngIf="showPopupForCancelOrder">
    <div class="col">
      <label for="refundAmount" class="col-form-label">Refund Amount :</label>
    </div>
    <div class="col">
      <input
        type="text"
        [(ngModel)]="refundAmount"
        id="refundAmount"
        class="form-control"
        maxlength="4"
        placeholder="Refund amount"
        (input)="validateNumberInput($event)"
      />
    </div>
    <br />
    <button
      (click)="closeCloseOrderPopUp()"
      type="button"
      class="btn btn-secondary"
    >
      Cancel
    </button>
    <button
      (click)="cancelOrderRequest()"
      style="float: right"
      type="button"
      class="btn btn-success"
    >
      Submit
    </button>
  </div>
  <!-------------------------------------------------------------------------->

  <!-------------------------------------------------------------------------->
  <div
    class="popup-overlay"
    *ngIf="showAdvancedPaymentPopUp"
    (click)="closeAdvancedPaymentPopUp()"
  ></div>
  <div class="popup" *ngIf="showAdvancedPaymentPopUp">
    <div class="col">
      <p style="font-size: large">Customer's Payment in Advance</p>
      <div class="row mt-4 mb-2">
        <div class="col">
          <label for="amountForMEUnit" class="col-form-label"
            >Amount paid :</label
          >
        </div>
        <div class="col">
          <input
            type="text"
            [(ngModel)]="advancePayment"
            id="amountForMEUnit"
            class="form-control"
            aria-describedby="amountForMEUnitHelp"
            maxlength="5"
            (input)="validateNumberInput($event)"
          />
        </div>
        <div class="col-2"></div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <label class="col-form-label">Payment Method:</label>
        </div>
        <div class="col">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="paymentMethod"
              id="UPI"
              value="UPI"
              [(ngModel)]="advancePaymentMethod"
            />
            <label class="form-check-label" for="UPI">UPI</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="paymentMethod"
              id="COD"
              value="COD"
              [(ngModel)]="advancePaymentMethod"
            />
            <label class="form-check-label" for="COD">COD</label>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="advancePaymentMethod === 'UPI'">
        <div class="col">
          <label for="amountForServiceProvider" class="col-form-label"
            >Amount transaction ID :</label
          >
        </div>
        <div class="col">
          <input
            type="text"
            [(ngModel)]="advancePaymentTransactionID"
            id="amountForServiceProvider"
            class="form-control"
            aria-describedby="amountForServiceProviderHelp"
            maxlength="35"
          />
        </div>
        <div class="col-2"></div>
      </div>
    </div>

    <br />
    <button
      (click)="closeAdvancedPaymentPopUp()"
      type="button"
      class="btn btn-secondary"
    >
      Cancel
    </button>
    <div
      *ngIf="spinnerForAdvancedPayment"
      style="float: right"
      class="spinner-border text-success"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <button
      *ngIf="!spinnerForAdvancedPayment"
      (click)="addAdvancedPaymentSubmit()"
      style="float: right"
      type="button"
      class="btn btn-success"
    >
      Submit
    </button>
  </div>
  <!-------------------------------------------------------------------------->

  <!-------------------------------------------------------------------------->
  <div
    class="popup-overlay"
    *ngIf="showUpdatePaymentDetailsPopUp"
    (click)="closePopup()"
  ></div>
  <div class="popup p-4" *ngIf="showUpdatePaymentDetailsPopUp">
    <div class="mt-2 payment-method">
      <label class="payment-option"
        >Payment Method :&nbsp;&nbsp;&nbsp;
        <input
          type="radio"
          name="paymentMethod"
          value="UPI"
          (change)="setPaymentMethod('UPI')"
        />
        UPI
      </label>
      &nbsp;&nbsp;&nbsp;
      <label class="payment-option">
        <input
          type="radio"
          name="paymentMethod"
          value="COD"
          (change)="setPaymentMethod('COD')"
        />
        COD
      </label>
    </div>
    <br />
    <div class="row mt-1">
      <div class="col">
        <p>Hours worked :</p>
      </div>
      <div class="col">
        <input
          type="number"
          [(ngModel)]="hoursWorked"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
    </div>
    <br />
    <div class="row mt-1">
      <div class="col">
        <p>Total amount :</p>
      </div>
      <div class="col">
        <input
          type="number"
          [(ngModel)]="totalAmount"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
    </div>
    <br />
    <button (click)="closePopup()" type="button" class="btn btn-secondary">
      Cancel
    </button>
    <div
      *ngIf="spinnerForPaymentDetails"
      style="float: right"
      class="spinner-border text-success"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <button
      *ngIf="!spinnerForPaymentDetails"
      (click)="saveUpdatedPaymentDetails()"
      style="float: right"
      type="button"
      class="btn btn-success"
    >
      Submit
    </button>
  </div>
  <!-------------------------------------------------------------------------->

  <div class="row">
    <div class="col">
      <p class="createULB"><u>Service Request Description</u></p>
    </div>

    <div class="col">
      <p (click)="goBack()" class="pageReference">
        Service request listings > Service request description
      </p>
    </div>
  </div>

  <div class="row mainCard m-4 p-4" *ngFor="let orderResult of orderResult">
    <div class="col">
      <div class="row m-2 p-2">
        <div class="col">
          <p class="requestHeading">
            {{ getServiceLabel(orderResult.serviceType) }}
          </p>

          <p style="color: rgb(105, 103, 103)">
            Order ID : {{ orderResult.orderID }}
          </p>

          <p>
            Service order date :
            <span class="orderDate">
              {{ convertUTCToIST(orderResult.orderedDate) }}</span
            >
            Service required date :
            <span class="orderForDate">
              {{ convertUTCToIST(orderResult.orderForDate) }}</span
            >
            <span class="orderForDate" *ngIf="orderResult.endDate"
              >to {{ convertUTCToIST(orderResult.endDate) }}</span
            >

            <span (click)="editDate()">
              <i class="fa-solid fa-pen-to-square fa-xl"></i>
            </span>
          </p>

          <div class="row">
            <div class="col-2">
              <button
                *ngIf="
                  orderResult.orderStatus == 'PEN' ||
                  orderResult.orderStatus == 'PRO'
                "
                (click)="transferServicePopUp()"
                type="button"
                class="btn btn-light"
              >
                Transfer service
              </button>
              
            </div>
            <div class="col">
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Update Order Status
                </button>
                <ul
                  class="dropdown-menu"
                  style="
                    text-align: center;
                    
                    background-color: transparent;
                  "
                >
                  <li>
                    <button
                      *ngIf="orderResult.orderStatus === 'PEN'"
                      (click)="orderStatusProgress()"
                      type="button"
                      style="width: 100%; margin-bottom: 5px"
                      class="btn btn-success"
                    >
                      Progress
                    </button>
                  </li>
                  <li>
                    <button
                      (click)="cancelOrder()"
                      type="button"
                      style="width: 100%"
                      class="btn btn-danger"
                    >
                      Cancel Order
                    </button>
                  </li>
                </ul>
              </div>
            </div>
           
          </div>
        </div>
      </div>

      <div class="row m-2 p-2">
        <div class="col card m-2 p-3">
          <p>
            <b><u>User Details</u></b>
          </p>
          <p>User name : {{ orderResult.userDetails.userName }}</p>
          <p>Phone number :{{ orderResult.address.deliveryNumber }}</p>
          <p>Address : {{ orderResult.address.address }}</p>
          <p>Building name : {{ orderResult.address.building }}</p>
          <p>Street name : {{ orderResult.address.street }}</p>
          <p>Land mark : {{ orderResult.address.landMark }}</p>

          <p>District : {{ orderResult.address.district }}</p>

          <p>Pincode : {{ orderResult.address.pinCode }}</p>
        </div>

        <!-- <div class="col card m-2 p-3">
          <p>
            <b><u>Payment Details</u></b>

            <button
              *ngIf="
                orderResult.orderStatus !== 'COM' &&
                orderResult.orderStatus !== 'CAN'
              "
              style="float: right"
              (click)="addAdvancedPayment()"
              type="button"
              class="btn btn-success"
            >
              Add advanced payment
            </button>
          </p>
          <p style="color: gray">
            Booking price : {{ orderResult.ratePerHour || 0 }} /
            {{ orderResult.rupeesUnit || "" }}
          </p>

          <p>Amount paid : {{ orderResult.totalServicePrice || 0 }}</p>
          <p>Working hours : {{ orderResult.totalWorkingHours || 0 }} hrs</p>
          <p>Refund amount : {{ orderResult.refundAmount || 0 }}</p>
          <p>
            Payment method :<span class="paymentMethodCSS">{{
              orderResult.paymentMethod
            }}</span>
          </p>
          <p>
            Payment status :
            <span class="paymentStatusCSS">{{
              getPaymentStatus(orderResult.paymentStatus)
            }}</span>
          </p>

          <div
            class="row"
            *ngIf="
              orderResult?.advancedPayment &&
              orderResult?.advancedPayment.length > 0
            "
          >
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Transanction ID</th>
                  </tr>
                </thead>
                <tbody
                  *ngFor="
                    let advancedPaymentTabe of orderResult?.advancedPayment;
                    let i = index
                  "
                >
                  <tr>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ convertUTCToIST(advancedPaymentTabe.date) }}</td>
                    <td>{{ advancedPaymentTabe.amount }}</td>
                    <td>{{ advancedPaymentTabe.paymentMethod }}</td>
                    <td>{{ advancedPaymentTabe.transactionID }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col"></div>
            <div class="col" *ngIf="orderResult.orderStatus !== 'COM' && orderResult.orderStatus !== 'CAN'">
              <button
                style="float: right"
                (click)="updatePaymentDetails()"
                type="button"
                class="btn btn-success"
              >
                Complete Payment Details
              </button>
            </div>
          </div>
        </div> -->

        <div class="col card m-2 p-3">
          <p>
            <b><u>Service Description</u></b>
          </p>
          <p>
            Service requested : {{ getServiceLabel(orderResult.serviceType) }}
          </p>
          <p>Description : {{ orderResult.serviceWorkDescription }}</p>

          <P
            >Order status :
            <span class="orderStatusCSS">{{
              getOrderStatus(orderResult.orderStatus)
            }}</span>
          </P>

          <div
            class="row"
            *ngIf="
              orderResult.orderStatus != 'COM' &&
              orderResult.orderStatus != 'PEN' &&
              orderResult.orderStatus != 'CAN'
            "
          >
            <div class="col">
              <button
                (click)="serviceProviderPopUp()"
                type="button"
                class="btn btn-danger"
              >
                Select Service Provider
              </button>
            </div>

            <!-------------------------------------------------------------------------->
            <div
              class="popup-overlay"
              *ngIf="selectServiceProviderPopUp"
              (click)="closeselectServiceProviderPopUp()"
            ></div>
            <div class="popup p-4" *ngIf="selectServiceProviderPopUp">
              <br />

              <div class="col">
                <h3>Select Service Providers</h3>
                <div class="service-provider-list">
                  <ul class="list-group">
                    <div
                      class="div"
                      *ngFor="let provider of serviceProviderList"
                    >
                      <li
                        (click)="toggleProviderSelection(provider)"
                        *ngIf="
                          provider.serviceName.includes(orderResult.serviceType)
                        "
                        class="list-group-item"
                      >
                        <span style="cursor: pointer">
                          {{ provider.username }}
                          <span *ngIf="isSelected(provider)">&#10004;</span>
                        </span>
                      </li>
                    </div>
                  </ul>
                </div>
                <div class="selected-providers mt-3">
                  <h4>Selected Providers:</h4>
                  <p>{{ selectedProviderDisplayText }}</p>
                </div>
              </div>

              <br />
              <button
                (click)="closeselectServiceProviderPopUp()"
                type="button"
                class="btn btn-secondary"
              >
                Cancel
              </button>
              <div
                *ngIf="spinnerForAddServiceProvider"
                style="float: right"
                class="spinner-border text-success"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <button
                *ngIf="!spinnerForAddServiceProvider"
                (click)="addServiceProvider()"
                style="float: right"
                type="button"
                class="btn btn-success"
              >
                Submit
              </button>
            </div>
            <!-------------------------------------------------------------------------->
          </div>
        </div>
      </div>

      <div class="row m-2 p-2">
        <div class="col card m-2 p-3">
          <p>
            <b><u>Payment Details</u></b>

            <button
              *ngIf="
                orderResult.orderStatus !== 'COM' &&
                orderResult.orderStatus !== 'CAN'
              "
              style="float: right"
              (click)="addAdvancedPayment()"
              type="button"
              class="btn btn-success"
            >
              Add advanced payment
            </button>
          </p>
          <p style="color: gray">
            Booking price : {{ orderResult.ratePerHour || 0 }} /
            {{ orderResult.rupeesUnit || "" }}
          </p>

          <p>Amount paid : {{ orderResult.totalServicePrice || 0 }}</p>
          <p>Working hours : {{ orderResult.totalWorkingHours || 0 }} hrs</p>
          <p>Refund amount : {{ orderResult.refundAmount || 0 }}</p>
          <p>
            Payment method :<span class="paymentMethodCSS">{{
              orderResult.paymentMethod
            }}</span>
          </p>
          <p>
            Payment status :
            <span class="paymentStatusCSS">{{
              getPaymentStatus(orderResult.paymentStatus)
            }}</span>
          </p>

          <div
            class="row"
            *ngIf="
              orderResult?.advancedPayment &&
              orderResult?.advancedPayment.length > 0
            "
          >
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Transanction ID</th>
                  </tr>
                </thead>
                <tbody
                  *ngFor="
                    let advancedPaymentTabe of orderResult?.advancedPayment;
                    let i = index
                  "
                >
                  <tr>
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ convertUTCToIST(advancedPaymentTabe.date) }}</td>
                    <td>{{ advancedPaymentTabe.amount }}</td>
                    <td>{{ advancedPaymentTabe.paymentMethod }}</td>
                    <td>{{ advancedPaymentTabe.transactionID }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col"></div>
            <div
              class="col"
              *ngIf="
                orderResult.orderStatus !== 'COM' &&
                orderResult.orderStatus !== 'CAN'
              "
            >
              <button
                style="float: right"
                (click)="updatePaymentDetails()"
                type="button"
                class="btn btn-success"
              >
                Complete Payment Details
              </button>
            </div>
          </div>
        </div>

        <div class="col card m-2 p-3">
          <p>
            <b><u>Service Provider</u></b>
          </p>
          <div
            *ngIf="
              orderResult.serviceProvider &&
                orderResult.serviceProvider.length > 0;
              else noProviderMessage
            "
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>

                  <th>Phone Number</th>

                  <th>Accepted</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let provider of orderResult.serviceProvider">
                  <td>{{ provider.name }}</td>

                  <td>{{ provider.phoneNumber }}</td>

                  <td>
                    <span
                      [ngClass]="
                        provider.isAccepted ? 'accepted' : 'not-accepted'
                      "
                    >
                      {{ provider.isAccepted ? "Accepted" : "Not Accepted" }}
                    </span>
                  </td>

                  <td>
                    <button
                      *ngIf="orderResult.orderStatus !== 'COM'"
                      (click)="
                        removeAssignedServiceProvider(provider.ProfileID)
                      "
                      class="btn btn-danger btn-sm"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ng-template #noProviderMessage>
            <br />
            <p style="text-align: center">
              <b>Service provider not assigned</b>
            </p>
          </ng-template>
        </div>
      </div>

      <div class="row m-2 p-2" *ngIf="orderResult.orderStatus == 'COM'">
        <div class="col card m-2 p-3">
          <p>
            <b><u>Service Order Closure</u></b>
            <button
              type="button"
              style="float: right"
              (click)="onEditServiceOrderClosure()"
              class="btn btn-outline-success"
            >
              Edit Details
            </button>
          </p>
          <p>
            Payment Distribution of
            <b *ngIf="orderResult.totalServicePrice">{{
              orderResult.totalServicePrice
            }}</b>
            <b *ngIf="!orderResult.totalServicePrice">-</b>
            Rs
          </p>
          <div class="row mb-2">
            <div class="col">
              <label for="amountForMEUnit" class="col-form-label"
                >Amount for ME Unit :</label
              >
            </div>
            <div class="col-2">
              <input
                type="text"
                [(ngModel)]="amountForMEUnit"
                id="amountForMEUnit"
                class="form-control"
                maxlength="4"
                [disabled]="!editServiceOrderClosure"
                (input)="validateNumberInput($event)"
              />
            </div>
            <div class="col-7"></div>
          </div>
          <div class="row">
            <div class="col">
              <label for="amountForServiceProvider" class="col-form-label">
                <u>Amount for Service Providers:</u>
              </label>
            </div>
          </div>
          <div
            class="row"
            *ngFor="let provider of orderResult.serviceProvider; let i = index"
          >
            <div class="col">
              <label
                for="amountForServiceProvider-{{ i }}"
                class="col-form-label"
                >{{ provider.name }}:</label
              >
            </div>
            <div class="col-2 mb-1">
              <input
                type="text"
                [(ngModel)]="provider.serviceAmount"
                id="amountForServiceProvider-{{ i }}"
                class="form-control"
                maxlength="4"
                [disabled]="!editServiceOrderClosure"
                (input)="validateNumberInput($event)"
              />
            </div>
            <div class="col-7"></div>
          </div>
          <div class="mt-3 mb-3">
            <label for="exampleInputEmail1" class="form-label">Remarks</label>
            <input
              maxlength="50"
              [(ngModel)]="remarks"
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              [disabled]="!editServiceOrderClosure"
            />
            <div id="emailHelp" class="form-text">
              Remarks from the customer
            </div>
          </div>
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <div
                *ngIf="spinnerForOrderCompletion"
                style="float: right"
                class="spinner-border text-success"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <button
                *ngIf="!spinnerForOrderCompletion"
                (click)="serviceOrderCompletion()"
                [disabled]="!editServiceOrderClosure"
                type="button"
                style="float: right"
                class="btn btn-success"
              >
                Service Order Completion
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
