import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Route, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { QsIDsService } from '../../services/qs-ids.service';
import { ServiceRequestListingsService } from '../../services/service-request-listings.service';

import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-service-request-listings',
  templateUrl: './service-request-listings.component.html',
  styleUrls: ['./service-request-listings.component.css'],
  providers: [DatePipe],
})
export class ServiceRequestListingsComponent implements OnInit {
  //variables
  startDate: any = null;
  endDate: any = null;
  requestList: any;
  spinnerForSearch: boolean = false;

  pendingCheckBox: boolean = false;
  processingCheckBox: boolean = false;
  assignedCheckBox: boolean = false;
  acceptedCheckBox: boolean = false;
  completedCheckBox: boolean = false;
  cancelledCheckBox: boolean = false;
  selectedStatus: string = null;
  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private QsIDsService: QsIDsService,
    private ServiceRequestListingsService: ServiceRequestListingsService
  ) {}

  ngOnInit() {
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();
    if (startDate) {
      console.log('service has start date');
      this.startDate = startDate;
      this.endDate = endDate;
      this.searchForServiceListings();
    }

    this.filterServiceListings();
  }

  filterServiceListings() {
    const pendingRequests =
      this.ServiceRequestListingsService.getPendingRequest();
    const processingRequests =
      this.ServiceRequestListingsService.getProcessingRequest();
    const assignedRequests =
      this.ServiceRequestListingsService.getAssignedRequest();
    const acceptedRequests =
      this.ServiceRequestListingsService.getAcceptedRequest();
    const completedRequests =
      this.ServiceRequestListingsService.getCompletedRequest();
    const cancelledRequests =
      this.ServiceRequestListingsService.getCancelledRequest();
    console.log('the pending requests from the service', pendingRequests);
    console.log(
      'the processingRequests requests from the service',
      processingRequests
    );
    console.log(
      'the assignedRequests requests from the service',
      assignedRequests
    );
    console.log(
      'the acceptedRequests requests from the service',
      acceptedRequests
    );
    console.log(
      'the completedRequests requests from the service',
      completedRequests
    );
    console.log(
      'the cancelledRequests requests from the service',
      cancelledRequests
    );
    if (pendingRequests === true) {
      this.getAllPendingService();
      console.log('get all pending requests is called');
    } else if (processingRequests === true) {
      this.getAllProcessingService();
      console.log('get all processing requests is called');
    } else if (assignedRequests === true) {
      this.getAllAssignedService();
      console.log('get all assigned requests is called');
    } else if (acceptedRequests === true) {
      this.getAllAcceptedService();
      console.log('get all accepted requests is called');
    } else if (completedRequests === true) {
      this.getAllCompletedService();
      console.log('get all completed requests is called');
    } else if (cancelledRequests === true) {
      this.getAllCancelledService();
      console.log('get all cancelled requests is called');
    } else {
      console.log('nothing found');
    }
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, label: string) {
    console.log(`${label}:`, event.value);
    this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
  }

  searchForServiceListings() {

    this.pendingCheckBox = false;
    this.processingCheckBox = false;
    this.assignedCheckBox = false;
    this.acceptedCheckBox = false;
    this.completedCheckBox = false;
    this.cancelledCheckBox = false;


    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      Swal.fire('Please select start date');
      return;
    }

    if (!endDate) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    } else if (new Date(endDate) < new Date(startDate)) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    }

    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.ServiceRequestListingsService.searchForServiceListings(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.requestList = data;
        this.spinnerForSearch = false;
        console.log('the request list', this.requestList);
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  // to get all pending requests--------------
  getAllPendingService() {
    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.startDate = null;
    this.endDate = null;

    this.pendingCheckBox = true;
    this.processingCheckBox = false;
    this.assignedCheckBox = false;
    this.acceptedCheckBox = false;
    this.completedCheckBox = false;
    this.cancelledCheckBox = false;

    this.ServiceRequestListingsService.searchForPendingServiceListings(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.requestList = data;
        this.spinnerForSearch = false;
        console.log('the request list', this.requestList);
        this.ServiceRequestListingsService.setPendingRequest();
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  // to get all pending requests--------------
  getAllProcessingService() {
    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.startDate = null;
    this.endDate = null;

    this.pendingCheckBox = false;
    this.processingCheckBox = true;
    this.assignedCheckBox = false;
    this.acceptedCheckBox = false;
    this.completedCheckBox = false;
    this.cancelledCheckBox = false;

    this.ServiceRequestListingsService.searchForProcessingListings(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.requestList = data;
        this.spinnerForSearch = false;
        console.log('the request list', this.requestList);
        this.ServiceRequestListingsService.setProcessingRequest();
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  // to get all pending requests--------------
  getAllAssignedService() {
    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.startDate = null;
    this.endDate = null;

    this.pendingCheckBox = false;
    this.processingCheckBox = false;
    this.assignedCheckBox = true;
    this.acceptedCheckBox = false;
    this.completedCheckBox = false;
    this.cancelledCheckBox = false;

    this.ServiceRequestListingsService.searchForAssignedListings(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.requestList = data;
        this.spinnerForSearch = false;
        console.log('the request list', this.requestList);
        this.ServiceRequestListingsService.setAssignedRequest();
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }
  // to get all accepted requests--------------
  getAllAcceptedService() {
    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.startDate = null;
    this.endDate = null;

    this.pendingCheckBox = false;
    this.processingCheckBox = false;
    this.assignedCheckBox = false;
    this.acceptedCheckBox = true;
    this.completedCheckBox = false;
    this.cancelledCheckBox = false;

    this.ServiceRequestListingsService.searchForAcceptedListings(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.requestList = data;
        this.spinnerForSearch = false;
        console.log('the request list', this.requestList);
        this.ServiceRequestListingsService.setAcceptedRequest();
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  // to get all completed requests--------------
  getAllCompletedService() {
    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.startDate = null;
    this.endDate = null;

    this.pendingCheckBox = false;
    this.processingCheckBox = false;
    this.assignedCheckBox = false;
    this.acceptedCheckBox = false;
    this.completedCheckBox = true;
    this.cancelledCheckBox = false;

    this.ServiceRequestListingsService.searchForCompletedListings(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.requestList = data;
        this.spinnerForSearch = false;
        console.log('the request list', this.requestList);
        this.ServiceRequestListingsService.setCompletedRequest();
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  // to get all cancelled requests--------------
  getAllCancelledService() {
    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.startDate = null;
    this.endDate = null;

    this.pendingCheckBox = false;
    this.processingCheckBox = false;
    this.assignedCheckBox = false;
    this.acceptedCheckBox = false;
    this.completedCheckBox = false;
    this.cancelledCheckBox = true;

    this.ServiceRequestListingsService.searchForCancelledListings(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.requestList = data;
        this.spinnerForSearch = false;
        console.log('the request list', this.requestList);
        this.ServiceRequestListingsService.setCancelledRequest();
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  goToServiceDescription(ID: string) {
    this.router.navigate(['/serviceRequestDescription'], {
      queryParams: {
        requestID: ID,
      },
    });
  }

  convertUtcToIst(dateStr: string): string {
    let date = new Date(dateStr);
    let istDate = new Date(date.getTime() + (5 * 60 + 30) * 60000);
    return this.datePipe.transform(istDate, 'yyyy-MM-dd');
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  calculateRemainingDays(orderForDate: string): number {
    const currentDate = new Date();
    const orderDate = new Date(orderForDate);
    const timeDiff = orderDate.getTime() - currentDate.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Calculate days remaining
    return dayDiff;
  }
}
