<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="analytics"><u>Service Request Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > Service Request Listing</p>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill" style="margin-right: 10px">
        <mat-label>Enter your start date</mat-label>
        <input
          matInput
          [(ngModel)]="startDate"
          [matDatepicker]="firstPicker"
          (dateChange)="onDateChange($event, 'First Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="firstPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #firstPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Enter your end date</mat-label>
        <input
          matInput
          [matDatepicker]="secondPicker"
          [(ngModel)]="endDate"
          (dateChange)="onDateChange($event, 'Second Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="secondPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #secondPicker></mat-datepicker>
      </mat-form-field>

      <button
        *ngIf="!spinnerForSearch"
        (click)="searchForServiceListings()"
        style="margin-left: 15px"
        type="button"
        class="btn btn-success"
      >
        Search
      </button>

      <div
        *ngIf="spinnerForSearch"
        style="margin-left: 15px"
        class="spinner-border text-success"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col">
      <!-- <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="pendingCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get pending request
        </label>
      </div> -->
      <button
        style="width: 100%"
        type="button"
        (click)="getAllPendingService()"
        class="btn btn-outline-primary"
        [ngClass]="{'btn-outline-primary': !pendingCheckBox, 'btn-primary': pendingCheckBox}"

        
      >
        Pending
      </button>
    </div>

    <div class="col">
      <!-- <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="processingCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get processing request
        </label>
      </div> -->
      <button
        style="width: 100%"
        type="button"
        (click)="getAllProcessingService()"
        class="btn btn-outline-primary"
        [ngClass]="{'btn-outline-primary': !processingCheckBox, 'btn-primary': processingCheckBox}"

      >
        Processing
      </button>
    </div>

    <div class="col">
      <!-- <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="assignedCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get assigned request
        </label>
      </div> -->
      <button
        style="width: 100%"
        type="button"
        (click)="getAllAssignedService()"
        class="btn btn-outline-primary"
        [ngClass]="{'btn-outline-primary': !assignedCheckBox, 'btn-primary': assignedCheckBox}"

      >
        Assigned
      </button>
    </div>

    <div class="col">
      <!-- <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="acceptedCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get accepted request
        </label>
      </div> -->
      <button
        style="width: 100%"
        type="button"
        (click)="getAllAcceptedService()"
        class="btn btn-outline-primary"
        [ngClass]="{'btn-outline-primary': !acceptedCheckBox, 'btn-primary': acceptedCheckBox}"

      >
        Accepted
      </button>
    </div>

    <div class="col">
      <!-- <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="completedCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get completed request
        </label>
      </div> -->
      <button
        style="width: 100%"
        type="button"
        (click)="getAllCompletedService()"
        class="btn btn-outline-primary"
        [ngClass]="{'btn-outline-primary': !completedCheckBox, 'btn-primary': completedCheckBox}"

      >
        Completed
      </button>
    </div>

    <div class="col">
      <!-- <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="cancelledCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get cancelled request
        </label>
      </div> -->
      <button
        type="button"
        style="width: 100%"
        (click)="getAllCancelledService()"
        class="btn btn-outline-primary"
        [ngClass]="{'btn-outline-primary': !cancelledCheckBox, 'btn-primary': cancelledCheckBox}"

      >
        Cancelled
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Service ID</th>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Service</th>
            <th scope="col">Date</th>
            <th scope="col">Amount for MEunit</th>
            <th scope="col">Status</th>
            <th scope="col">Order Method</th>
            <th scope="col">Remaining Days</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody *ngFor="let requestList of requestList; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>
              <i class="fa-solid fa-id-card"></i> {{ requestList.orderID }}
            </td>
            <td>{{ requestList.userDetails.userName }}</td>
            <td>
              <i class="fa-solid fa-phone"></i>
              {{ requestList.address.deliveryNumber }}
            </td>
            <td>{{ getServiceLabel(requestList.serviceType) }}</td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUtcToIst(requestList.orderForDate) }}
            </td>
            <td>{{ requestList.amountForMEUnit }}</td>
            <td>
              <span
                *ngIf="requestList.orderStatus == 'PEN'"
                class="badge rounded-pill bg-warning"
                >PENDING</span
              >
              <span
                *ngIf="requestList.orderStatus == 'ACC'"
                class="badge rounded-pill bg-primary"
                >ACCEPTED</span
              >
              <span
                *ngIf="requestList.orderStatus == 'COM'"
                class="badge rounded-pill bg-success"
                >COMPLETED</span
              >
              <span
                *ngIf="requestList.orderStatus == 'CAN'"
                class="badge rounded-pill bg-danger"
                >CANCELLED</span
              >
              <span
                *ngIf="requestList.orderStatus == 'ASS'"
                class="badge rounded-pill bg-warning"
                >ASSIGNED</span
              >
              <span
                *ngIf="requestList.orderStatus == 'PRO'"
                class="badge rounded-pill bg-info"
                >PROCESSING</span
              >
            </td>
            <td>
              {{ requestList.orderMethod }}
            </td>
            <td>
              <span
                *ngIf="calculateRemainingDays(requestList.orderForDate) >= 0"
              >
                {{ calculateRemainingDays(requestList.orderForDate) }}
              </span>
              <span
                *ngIf="calculateRemainingDays(requestList.orderForDate) < 0"
              >
                0
              </span>
            </td>
            <td>
              <button
                (click)="goToServiceDescription(requestList.orderID)"
                type="button"
                class="btn btn-link"
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
