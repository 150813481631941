<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>ME Units Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Quick serve > ME Units</p>
    </div>
  </div>

  <br />

  <button
    *ngIf="spinnerForMEUnitListings"
    class="btn btn-primary"
    type="button"
    disabled
  >
    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
    <span role="status">Loading...</span>
  </button>

  <div
    class="row p-4 mb-3"
    style="background-color: rgb(12, 74, 110); border-radius: 15px"
  >
    <div class="col" *ngIf="isStateAdmin() || isSuperAdmin()">
      <select
        class="form-select"
        id="districtSelect"
        (change)="onDistrictSelected($event)"
        aria-label="Default select example"
        [(ngModel)]="districtValue"
        
      >
        <option *ngFor="let district of districts" [value]="district.value">
          {{ district.label }}
        </option>
      </select>
    </div>
    <div class="col">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="phoneNumber"
        [ngModelOptions]="{ standalone: true }"
        (input)="onPhoneNumberInput($event)"
        id="email"
        maxlength="10"
        placeholder="Enter phone number"
      />
    </div>
    <div class="col">
      <button
        *ngIf="!spinnerForSearch"
        type="button"
        (click)="initializeBasedOnUserRole()"
        class="btn btn-success"
      >
        Search
      </button>
      <div
        *ngIf="spinnerForSearch"
        class="spinner-border text-success"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="col" *ngIf="!isStateAdmin() || !isSuperAdmin()"></div>
  </div>

  <div class="row" *ngIf="!spinnerForMEUnitListings">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Reg CMMU</th>
            <th scope="col">Reg Distirct</th>
            <th scope="col">Approval Status</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody *ngFor="let MEUnitListings of MEUnitListings; let i = index">
          <tr>
            <th scope="row">{{ (currentPage - 1) * pageSize + i + 1 }}</th>
            <td>{{ MEUnitListings.userName }}</td>
            <td>
              <i class="fa-solid fa-phone" *ngIf="MEUnitListings.mobile"></i>
              {{ MEUnitListings.mobile }}
            </td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUTCToIST(MEUnitListings.registeredDate) }}
            </td>
            <td>{{ MEUnitListings.registeredULBName }}</td>
            <td>{{ MEUnitListings.address.district }}</td>

            <td>
              <span
                [class.active]="MEUnitListings.isApproved"
                [class.pending]="!MEUnitListings.isApproved"
              >
                {{ MEUnitListings.isApproved ? "Active" : "Pending" }}</span
              >
            </td>
            <td>
              <button
                type="button"
                (click)="goToMeUnitDescription(MEUnitListings.profileID)"
                class="btn btn-link"
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <nav aria-label="ME Unit listings pagination">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a
              class="page-link"
              (click)="onPageChange(currentPage - 1)"
              tabindex="-1"
              >Previous</a
            >
          </li>
          <li
            class="page-item"
            *ngFor="let page of getPaginationArray(); let i = index"
            [class.active1]="currentPage === i + 1"
          >
            <a class="page-link" (click)="onPageChange(i + 1)">{{ i + 1 }}</a>
          </li>
          <li
            class="page-item"
            [class.disabled]="currentPage === getTotalPages()"
          >
            <a class="page-link" (click)="onPageChange(currentPage + 1)"
              >Next</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
